<template>
  <div id="orders-list">
    <!-- TABLE -->
    <b-table
      ref="quotes-table"
      :busy="quotesLoading"
      :empty-text="$t('common.no_record_found')"
      :fields="quotesFields"
      :items="quotes"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="position-relative table-dropdown-action"
      no-local-sorting
      no-sort-reset
      primary-key="id"
      thead-class="text-nowrap"
      @sort-changed="updateSort"
    >
      <!-- LOADING -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>

      <!-- CELL -->
      <!-- aircraft -->
      <template #cell(aircraftName)="data">
        <span> {{ data.item.aircraftName }} </span>
      </template>
      <!-- flightType -->
      <template #cell(flightType)="data">
        {{ data.item.flightType | enumTranslate('flight_type') }}
      </template>
      <!-- movementType -->
      <template #cell(movementType)="data">
        {{ data.item.movementType | enumTranslate('movement_type') }}
      </template>
      <!-- tripName -->
      <template #cell(tripName)="data">
        <b-link :to="{ name: 'trip-view', params: { trip_id: data.item.tripId } }" target="_blank">
          {{ data.item.tripName }}
        </b-link>
      </template>
      <!-- number -->
      <template #cell(reference)="data">
        <b-link>
          {{ data.item.reference }}
        </b-link>
      </template>
      <!-- createdAt -->
      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{ data.item.createdAt | dateFormat }}
        </span>
      </template>
      <!-- provider -->
      <template #cell(customerName)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar :src="data.item.customerAvatar" :text="avatarText(data.item.customerName)" size="32" />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.customerName }}
          </span>
        </b-media>
      </template>
      <!-- customer -->
      <template #cell(providerName)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar :src="data.item.providerAvatar" :text="avatarText(data.item.providerName)" size="32" />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.providerName }}
          </span>
          <p class="d-block text-nowrap">
            {{ data.item.eventAirportName }}
          </p>
        </b-media>
      </template>
      <!-- eta etd -->
      <template #cell(et)="data">
        <b-row v-if="data.item.departureSlotDate" align-v="center">
          <span class="text-nowrap">
            <font-awesome-icon class="mr-50" icon="plane-departure" />
            {{
              data.item.departureSlotDate | dateFormatUtc
            }} ({{ data.item.departureSlotDate | timeFormatUtc }} {{ $t('common.utc') }})
          </span>
        </b-row>
        <b-row v-else-if="data.item.departureEventDateTime" align-v="center">
          <span class="text-nowrap">
            <font-awesome-icon class="mr-50" icon="plane-departure" />
            {{
              data.item.departureEventDateTime | dateFormatUtc
            }} ({{ $moment(data.item.departureEventDateTime).utc().format('HH:mm') }} {{ $t('common.utc') }})
          </span>
        </b-row>
        <b-row v-if="data.item.arrivalSlotDate">
          <span class="text-nowrap">
            <font-awesome-icon class="mr-50" icon="plane-departure" />
            {{ data.item.arrivalSlotDate | dateFormatUtc }} ({{
              $moment(data.item.arrivalSlotDate).utc().format('HH:mm')
            }} {{
              $t('common.utc')
            }})
          </span>
        </b-row>
        <b-row v-else-if="data.item.arrivalEventDateTime" align-v="center">
          <span class="text-nowrap">
            <font-awesome-icon class="mr-50" icon="plane-departure" />
            {{
              data.item.arrivalEventDateTime | dateFormatUtc
            }} ({{ $moment(data.item.arrivalEventDateTime).utc().format('HH:mm') }} {{ $t('common.utc') }})
          </span>
        </b-row>
      </template>
      <!-- service -->
      <template #cell(lines)="data">
        <b-button v-if="data.item.lines && data.item.lines.length > 0" :id="data.item.id.toString()" class="btn-icon" variant="flat">
          <font-awesome-icon icon="list" size="lg" />
        </b-button>
        <b-tooltip
          :target="data.item.id.toString()"
          boundary-padding="1"
          no-fade
          noninteractive
          placement="right"
          variant="light"
        >
          <b-card :title="data.item.reference" border-variant="primary">
            <b-card-text>
              <ul class="text-left d-inline">
                <li v-for="(service, index) in data.item.lines" :key="index" class="ml-1">
                  {{ service.name }}
                </li>
              </ul>
            </b-card-text>
          </b-card>
        </b-tooltip>
      </template>
      <!-- price -->
      <template #cell(price)="data">
        <b-row no-gutters>
          <small class="text-nowrap">
            {{ $t('order.vat_free') }} :
            {{ data.item.priceExcludingTaxes | priceFormat }}
          </small>
        </b-row>
        <b-row no-gutters>
          <small class="text-nowrap">
            {{ $t('common.vat') }} :
            {{ data.item.taxes | priceFormat }}
          </small>
        </b-row>
        <b-row no-gutters>
          <small class="text-nowrap">
            {{ $t('order.total') }} :
            {{ data.item.priceIncludingTaxes | priceFormat }}
          </small>
        </b-row>
      </template>
      <!-- payment terms -->
      <template #cell(payment_term)="data">
        <span v-if="data.item.paymentTerm">{{ data.item.paymentTerm | enumTranslate('quote_payment_term') }}</span>
      </template>
      <!-- status -->
      <template #cell(state)="data">
        <b-badge v-if="data.item.cancelled" class="text-capitalize" pill variant="light-danger">
          {{ $t('state.cancelled') }}
        </b-badge>
        <b-badge v-else-if="data.item.refused" class="text-capitalize" pill variant="light-danger">
          {{ $t('state.refused') }}
        </b-badge>
        <b-badge v-else-if="data.item.state" :variant="resolveQuoteStateVariant(data.item.state)" pill>
          {{ data.item.state | enumTranslate('quote_state') }}
        </b-badge>
      </template>
      <!-- action -->
      <template #cell(actions)="data">
        <app-data-table-actions
          :show-delete="false"
          :show-duplicate="false"
          :show-edit="false"
          :show-show="$can('TRIP_ACCOUNTING_VIEW')"
          display-mode="inline-icon"
          table-name="quotes"
          @show="$router.push({ name: 'OrderViewShow', params: { id: data.item.id } })"
        />
      </template>
    </b-table>
    <!--TABLE BOTTOM-->
    <div class="mx-2 mb-2">
      <app-data-table-bottom
        :page="page"
        :per-page="numberOfItemsPerPage"
        :total-count="pagination.totalItems"
        table-name="quotes"
        @pagination=";(page = $event), fetchQuotes(true)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import UIHelper from '@/helpers/ui'
import number from '@/mixins/number'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'

export default {
  name: 'OrdersList',

  components: {
    AppDataTableBottom,
    AppDataTableActions,
  },
  mixins: [UIHelper, number],

  data() {
    return {
      calendarMode: false,
      showFilters: false,
    }
  },

  computed: {
    ...mapState('quote', ['quotesLoading', 'quotes', 'pagination']),
    ...mapState('appConfig', ['lang']),
    ...mapGetters('auth', ['isBuyer', 'isSeller', 'isOperator']),

    ...mapFields('quote', ['paginate.page', 'paginate.numberOfItemsPerPage', 'dataTableSort.sortBy', 'dataTableSort.sortDesc']),

    quotesFields() {
      return [
        {
          key: 'aircraftName',
          label: this.$t('order.aircraft'),
          sortable: true,
        },
        {
          key: this.isBuyer && 'tripName',
          label: this.isBuyer && this.$t('order.trip_name'),
          sortable: true,
        },
        {
          key: (this.isSeller || this.isOperator) && 'flightType',
          label: (this.isSeller || this.isOperator) && this.$t('order.flight_type'),
          sortable: true,
        },
        {
          key: 'movementType',
          label: this.$t('order.movement_type'),
          sortable: true,
        },
        {
          key: 'reference',
          label: this.$t('order.order_number'),
          sortable: true,
        },
        {
          key: 'createdAt',
          label: this.$t('order.created_at'),
          sortable: true,
        },
        {
          key: (this.isBuyer || this.isOperator) && 'providerName',
          label: (this.isBuyer || this.isOperator) && this.$t('order.provider'),
          sortable: true,
        },
        {
          key: (this.isSeller || this.isOperator) && 'customerName',
          label: (this.isSeller || this.isOperator) && this.$t('order.customer'),
          sortable: true,
        },
        { key: 'et', label: this.$t('order.eta_and_etd') },
        { key: 'lines', label: this.$t('order.services') },
        { key: 'price', label: this.$t('order.price') },
        { key: 'payment_term', label: this.$t('quotation.payment.terms') },
        { key: 'state', label: this.$t('order.status'), sortable: true },
        {
          key: 'actions',
          label: this.$tc('action.title', 2),
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ]
    },
  },

  mounted() {
    this.fetchQuotes()
  },

  methods: {
    ...mapActions('quote', ['fetchQuotes', 'resetState', 'setSort']),

    updateSort(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.setSort({ sortBy: e.sortBy, sortDesc: e.sortDesc })
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].name !== 'OrderView') {
      this.resetState()
    }
    next()
  },
}
</script>
