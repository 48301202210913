<template>
  <div id="orders-list">
    <!-- Filters -->
    <OrdersFilters v-show="showFilters" :calendar-mode="calendarMode" />
    <b-card no-body class="mb-0 mt-1">
      <app-data-table-top
        :disabled="quotesLoading"
        :filters-count="filtersCount"
        :per-page="numberOfItemsPerPage"
        :per-page-options="dataTable.parPageOption"
        :search-filter-options="searchFilterOptions"
        search-default-filter="reference"
        :show-filter="$can('TRIP_ACCOUNTING_VIEW')"
        :show-per-page="!calendarMode && $can('TRIP_ACCOUNTING_VIEW')"
        :show-search="$can('TRIP_ACCOUNTING_VIEW')"
        condensed="lg"
        size="md"
        table-name="quotes"
        @table-select-per-page=";(numberOfItemsPerPage = $event), fetchQuotes(true)"
        @table-search-text=";(search = $event), fetchQuotes(true)"
        @table-search-filter=";(searchField = $event), fetchQuotes(true)"
        @table-filter="showFilters = $event"
      >
        <template v-if="calendarMode" #search>
          <b-form-group :label="$t('dashboard.trip.calendar_date')" label-for="start-at" class="mb-0">
            <b-form-datepicker v-model="dateCalendarSelected" :locale="$i18n.locale" :no-flip="true" :placeholder="$t('dashboard.trip.calendar_date')" />
          </b-form-group>
        </template>
        <template #actions>
          <b-row v-if="$can('TRIP_ACCOUNTING_VIEW') && !selectedOrganization.operator" class="mb-lg-1">
            <b-col cols="12" class="d-flex justify-content-end">
              <span class="d-flex justify-content-end align-items-center">
                <app-checkbox-calendar
                  id="calendar-mode"
                  v-model="calendarMode"
                  class="mb-0"
                />
              </span>
            </b-col>
          </b-row>
        </template>
      </app-data-table-top>
      <OrdersCalendar v-if="calendarMode" :quotes-data="quotes" />
      <OrdersList v-else />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import OrdersFilters from '@/views/order/OrdersFilters.vue'
import OrdersList from '@/views/order/OrdersList.vue'
import AppCheckboxCalendar from '@/components/AppCheckboxCalendar.vue'
import AppDataTableTop from '@/components/AppDataTableTop.vue'

export default {
  name: 'OrdersManager',

  components: {
    OrdersFilters,
    OrdersList,
    AppCheckboxCalendar,
    AppDataTableTop,
    OrdersCalendar: () => import('@/views/order/OrdersCalendar.vue'),
  },

  data() {
    return {
      calendarMode: false,
      showFilters: false,
    }
  },

  computed: {
    ...mapState('auth', ['selectedOrganization']),
    ...mapState('quote', ['quotesLoading', 'quotes', 'searchText', 'searchFilterOptions']),
    ...mapState('appConfig', ['dataTable']),

    ...mapGetters('quote', ['filtersCount']),

    ...mapFields('quote', ['paginate.numberOfItemsPerPage', 'searchField', 'dateCalendarSelected']),

    search: {
      get() {
        return this.searchText
      },
      set(value) {
        this.setSearch(value)
      },
    },
  },

  methods: {
    ...mapActions('quote', ['fetchQuotes', 'resetState', 'setSearch', 'clearFilters']),
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].name !== 'OrderView') {
      this.resetState()
    }
    next()
  },
}
</script>
