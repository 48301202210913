<template>
  <div id="orders-filters">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          {{ $t('common.filters') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col v-if="!calendarMode" cols="12" md="4">
            <!-- createdAtStart -->
            <b-form-group :label="$t('common.start_at')" label-for="state-filter">
              <b-overlay :show="quotesLoading">
                <b-form-datepicker
                  id="start-at"
                  v-model="createdAtStart"
                  :locale="$i18n.locale"
                  :max="createdAtEnd"
                  :placeholder="$t('common.start_at')"
                  size="sm"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col v-if="!calendarMode" cols="12" md="4">
            <!-- createdAtEnd -->
            <b-form-group :label="$t('common.end_at')" label-for="state-filter">
              <b-overlay :show="quotesLoading">
                <b-form-datepicker
                  id="end-at"
                  v-model="createdAtEnd"
                  :locale="$i18n.locale"
                  :min="createdAtStart"
                  :placeholder="$t('common.end_at')"
                  size="sm"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <!-- state -->
            <enum-select
              id="state-filter"
              v-model="statesCodes"
              :label="$t('order.status')"
              :selectable="() => statesCodes.length < 3"
              class="select-size-sm"
              enum-class="quoteState"
              multiple
            />
          </b-col>
          <b-col cols="12" md="4">
            <!-- payment terms -->
            <enum-select
              id="payment-terms-filter"
              v-model="paymentTerms"
              :label="$t('quotation.payment.terms')"
              class="select-size-sm"
              enum-class="quotePaymentTerm"
            />
          </b-col>
          <b-col v-if="!calendarMode" cols="12" md="4">
            <!-- aircrafts -->
            <b-form-group :label="$tc('common.aircraft', 0)" label-for="aircraft-filter">
              <b-overlay :show="filtersOptionsLoading || quotesLoading">
                <v-select
                  id="aircraft-filter"
                  v-model="aircraftsIds"
                  :options="filtersOptions.aircrafts"
                  :placeholder="$t('order.filter.by_aircraft')"
                  :reduce="name => name.id"
                  :selectable="() => aircraftsIds.length < 3"
                  class="select-size-sm"
                  label="name"
                  multiple
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col v-if="isSeller && !calendarMode" cols="12" md="4">
            <!-- flight missions -->
            <enum-select
              id="flight-mission-filter"
              v-model="flightMission"
              :label="$t('order.flight_mission')"
              class="select-size-sm"
              enum-class="flightMission"
            />
          </b-col>
          <b-col v-if="isSeller && !calendarMode" cols="12" md="4">
            <!-- movement types -->
            <enum-select
              id="movement-type-filter"
              v-model="movementType"
              :label="$t('order.movement_type')"
              class="select-size-sm"
              enum-class="movementType"
            />
          </b-col>
          <b-col v-if="!isSeller && !calendarMode" cols="12" md="4">
            <!-- airports -->
            <b-form-group :label="$t('common.airport')" label-for="airport-filter">
              <b-overlay :show="filtersOptionsLoading || quotesLoading">
                <v-select
                  id="airport-filter"
                  v-model="airportsIds"
                  :options="filtersOptions.airports"
                  :placeholder="$t('order.filter.by_airport')"
                  :reduce="name => name.id"
                  :selectable="() => airportsIds.length < 3"
                  class="select-size-sm"
                  label="name"
                  multiple
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col v-if="isSeller || isOperator" cols="12" md="4">
            <!-- customer -->
            <b-form-group :label="$t('order.customer')" label-for="customers-filter">
              <b-overlay :show="filtersOptionsLoading || quotesLoading || quotesCalendarLoading">
                <v-select
                  id="customers-filter"
                  v-model="customersIds"
                  :options="filtersOptions.customers"
                  :placeholder="$t('order.filter.by_customer')"
                  :reduce="name => name.id"
                  :selectable="() => customersIds.length < 3"
                  class="select-size-sm"
                  label="legalName"
                  multiple
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col v-if="isBuyer || isOperator" cols="12" md="4">
            <!-- provider -->
            <b-form-group :label="$t('order.provider')" label-for="providers-filter">
              <b-overlay :show="filtersOptionsLoading || quotesLoading">
                <v-select
                  id="providers-filter"
                  v-model="providersIds"
                  :options="filtersOptions.providers"
                  :placeholder="$t('order.filter.by_provider')"
                  :reduce="name => name.id"
                  :selectable="() => providersIds.length < 3"
                  class="select-size-sm"
                  label="legalName"
                  multiple
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <!-- services -->
            <b-form-group :label="$tc('common.service')" label-for="services-filter">
              <b-overlay :show="filtersOptionsLoading || quotesLoading || quotesCalendarLoading">
                <v-select
                  id="services-filter"
                  v-model="productsIds"
                  :options="filtersOptions.products"
                  :placeholder="$t('order.filter.by_service')"
                  :reduce="name => name.id"
                  :selectable="() => productsIds.length < 3"
                  class="select-size-sm"
                  label="name"
                  multiple
                >
                  <template #option="{ name }">
                    {{ name | trans }}
                  </template>
                  <template #selected-option="{ name }">
                    {{ name | trans }}
                  </template>
                  <template #no-options> {{ $t('common.no_option_found') }} </template>
                </v-select>
              </b-overlay>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex align-items-center justify-content-end">
            <b-button
              id="action-reset-filter"
              :disabled="!aircraftsIds.length && !airportsIds.length && !productsIds.length && !statesCodes.length && !paymentTerms && !movementType && !flightMission && !customersIds.length && !providersIds.length && !createdAtStart && !createdAtEnd"
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              @click="clearFilters(calendarMode)"
            >
              {{ $t('action.clear') }}
            </b-button>
            <b-button id="action-filter" v-ripple.400 :disabled="!filtersChanged" size="sm" @click="!calendarMode ? fetchQuotes(true) : fetchQuotesCalendar(), (filtersChanged = false)">
              {{ $t('action.filter') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'OrdersFilters',
  props: {
    calendarMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filtersChanged: false,
    }
  },

  computed: {
    ...mapState('quote', ['filtersOptions', 'filtersOptionsLoading', 'quotesLoading', 'quotesCalendarLoading', 'filter']),
    ...mapState('appConfig', ['lang']),
    ...mapGetters('auth', ['isBuyer', 'isSeller', 'isOperator', 'selectedOrganizationId']),

    ...mapFields('quote', [
      'filter.aircraftsIds',
      'filter.airportsIds',
      'filter.productsIds',
      'filter.statesCodes',
      'filter.paymentTerms',
      'filter.movementType',
      'filter.flightMission',
      'filter.customersIds',
      'filter.providersIds',
      'filter.createdAtStart',
      'filter.createdAtEnd',
    ]),
  },

  watch: {
    filter: {
      handler() {
        this.filtersChanged = true
      },
      deep: true,
    },
  },

  mounted() {
    this.fetchQuoteFiltersOption()
  },

  methods: {
    ...mapActions('quote', ['fetchQuotes', 'fetchQuotesCalendar', 'fetchQuoteFiltersOption', 'clearFilters']),
  },
}
</script>

<style lang="scss">
.vs__search,
.vs__search:focus {
  line-height: 2.1 !important;
}
</style>
